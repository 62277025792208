<template>
  <div class="settings">
    <Header title="个人设置" index="首页" beforeTitle="个人设置" />
    <div class="content_all">
      <div class="bgpic">
        <div class="Drive">
          <el-upload
            ref="imgupload"
            action="string"
            :http-request="httpRequest"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :limit="1"
          >
            <img :src="userSite + this.url" class="avatar" />
            <el-button class="addpic">
              <img src="../assets/images/addpic.png" />
            </el-button>
          </el-upload>
        </div>
      </div>
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <div class="set1">
              <el-form
                :model="resetuserform"
                label-width="80px"
                :rules="resetUserRule"
                ref="resetUserRel"
                :label-position="labelPosition"
              >
                <el-form-item label="真实姓名">
                  <el-input v-model="resetuserform.true_name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码">
                  <el-input v-model="resetuserform.tel"></el-input>
                </el-form-item>
                <el-form-item label="密码">
                  <el-input v-model="resetuserform.password"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" style="margin-right: 0">
                  <el-input v-model="resetuserform.email"></el-input>
                </el-form-item>
                <el-form-item label="入职时间">
                  <el-date-picker
                    v-model="time_in"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="合同开始">
                  <el-date-picker
                    v-model="time_start"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="合同结束">
                  <el-date-picker
                    v-model="time_end"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="户籍所在地" class="form_area">
                  <el-input placeholder="请输入户籍所在地" v-model="area_home"></el-input>
                </el-form-item>
                <el-form-item label="现居住地址" class="form_area">
                  <el-input placeholder="请输入现居住地" v-model="area"></el-input>
                </el-form-item>
                <!-- 上传合同附件 -->
                <div class="upload">
                  <el-upload
                    class="upload-demo"
                    ref="imgupload"
                    action="string"
                    :http-request="httpRequestContract"
                    :show-file-list="false"
                    :before-upload="beforeAvatarUpload"
                    :file-list="fileList"
                  >
                    <el-button type="primary" class="ChooseFile">请上传合同附件</el-button>
                  </el-upload>
                  <span>（上传文件格式支持pdf，jpg，word，压缩包zip/rar）</span>
                </div>
                <!-- 身份证附件照 -->
                <span style="display: block; margin: 20px 0">身份证</span>
                <div class="id_box">
                  <div class="id_flex">
                    <el-upload action="#" list-type="picture-card" :auto-upload="false">
                      <!-- <i slot="default" class="el-icon-plus"></i> -->
                      <span class="id_text">点击上传正面</span>
                      <img src="../assets/images/id_f.png" alt class="id_img" />
                      <div slot="file" slot-scope="{ file }">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                  </div>
                  <div class="id_flex">
                    <el-upload action="#" list-type="picture-card" :auto-upload="false">
                      <!-- <i slot="default" class="el-icon-plus"></i> -->
                      <span class="id_text">点击上传反面</span>
                      <img src="../assets/images/id_bs.png" alt class="id_img" />
                      <div slot="file" slot-scope="{ file }">
                        <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                  </div>
                </div>
                <Study />
                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt />
                </el-dialog>
                <div class="foot_TJ">
                  <el-button
                    type="primary"
                    :loading="btnLoadingInfo"
                    @click="resetuseronSubmit()"
                  >确定</el-button>
                </div>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="修改密码" name="second">
            <div class="set1">
              <el-form
                :model="resetLoginform"
                :rules="resetLoginRule"
                ref="resetLoginRel"
                label-width="80px"
                :label-position="labelPosition"
              >
                <el-form-item label="原密码">
                  <el-input v-model="resetLoginform.old_password"></el-input>
                </el-form-item>
                <el-form-item label="新密码">
                  <el-input v-model="resetLoginform.new_password"></el-input>
                </el-form-item>
                <el-form-item label="确认密码">
                  <el-input v-model="resetLoginform.confirm_password"></el-input>
                </el-form-item>
                <div class="foot_TJ">
                  <el-button type="primary" :loading="btnLoadingPassword" @click="onSubmit">确定</el-button>
                </div>
              </el-form>
            </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="我的收藏" name="third"></el-tab-pane>
          <el-tab-pane label="站内消息" name="fourth"></el-tab-pane>-->
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Study from '../components/setTing/index.vue'
import { mapGetters, mapState } from 'vuex'
import axios from 'axios'
import site from '../components/global.vue'
export default {
  inject: ['reload'],
  data () {
    return {
      btnLoadingInfo: false,//个人信息提交按钮动画
      btnLoadingPassword: false,//修改密码提交按钮动画
      fileList: [],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      area: '', //现居住地
      area_home: "",//户籍所在地
      time_in: '', //入职时间
      time_start: '', //合同开始
      time_end: '', //合同结束
      userSite: site.userSite,
      url: '',
      activeName: 'first',
      labelPosition: 'top',
      resetuserform: {
        true_name: '',
        password: '',
        tel: '',
        email: '',
        member_id: '',
      },
      resetLoginform: {
        old_password: '',
        new_password: '',
        confirm_password: '',
      },
      resetLoginRule: {},
      resetUserRule: {
        true_name: [
          {
            required: true,
            message: '请输入昵称',
            trigger: 'blur',
          },
        ],
        tel: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  components: {
    Study,
  },
  created () {
    this.token = localStorage.getItem('token')
  },
  computed: {
    ...mapState(['Picture']),
  },
  mounted () {
    if (this.token) {
      this.getViewSelf()
    }
  },
  methods: {
    getViewSelf () {
      this.axios
        .get('/api/member/view_self', {
          params: {
            token: this.token,
          },
        })
        .then((res) => {
          console.log(res)
          this.url = res.data.avatar
          this.resetuserform = res.data
          this.resetuserform.true_name = res.data.true_name
          this.resetuserform.member_id = res.data.id
        })
    },
    handleRemove (file) {
      console.log(file)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload (file) {
      console.log(file)
    },
    resetuseronSubmit () {
      this.$refs.resetUserRel.validate((vaild) => {
        if (vaild) {
          this.btnLoadingInfo = true
          this.axios
            .post('/api/member/store', this.resetuserform)
            .then((res) => {
              this.reload()
              this.$message({
                type: 'success',
                message: res.message,
              })
              this.btnLoadingInfo = false
            })
            .catch((res) => {
              this.btnLoadingInfo = false
            })
        } else {
          this.$message.error('')
        }
      })
    },
    // 上传头像
    httpRequest (param) {
      let avatar = param.file
      const form = new FormData()
      form.append('avatar', avatar)
      this.upload('/api/annex/upload_avatar', form).then((res) => {
        console.log(res)
        this.getViewSelf()
        this.$message({
          type: 'success',
          message: '头像上传保存成功'
        })
      })
    },
    // 上传合同
    httpRequestContract (param) {

    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          this.getViewSelf()
          return new Promise((resolve, reject) => {
            resolve(res)
          })
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    // 上传图片前的过滤
    beforeAvatarUpload (avatar) {
      const isJPG =
        avatar.type === 'image/png' || 'image/jpg' || 'image/jpeg'
      const isLt2M = avatar.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    onSubmit () {
      this.$refs.resetLoginRel.validate((vaild) => {
        if (vaild) {
          this.btnLoadingPassword = true
          this.axios
            .post('/api/member/reset_password', this.resetLoginform)
            .then((res) => {
              this.reload()
              if (res.code == 1) {
                this.$message({
                  type: 'success',
                  message: res.message,
                })
                this.btnLoadingPassword = false
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                })
                this.btnLoadingPassword = false
              }
            })
            .catch((res) => {
              this.btnLoadingPassword = false
            })
        } else {
          this.$message.error('请完善信息')
        }
      })
    },
  },
}
</script>

<style scoped="scoped">
.bgpic {
  background: url(../assets/images/bg1.png);
  height: 340px;
  position: relative;
}

.el-tabs {
  padding: 40px;
}

.set1 .el-form-item {
  display: inline-block;
  width: 23.5%;
  margin-right: 2%;
}

.Drive {
  background: #fff;
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  bottom: -50px;
  left: 45%;
  box-shadow: 0px 6px 17px 1px rgba(0, 0, 0, 0.06);
}

.addpic {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 50%;
  padding: 0;
  background: #fff;
  box-shadow: 0px 6px 17px 1px rgba(0, 0, 0, 0.06);
}
.form_area {
  width: 100% !important;
}
.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100% !important;
}
.upload {
  display: flex;
  align-items: center;
  margin: 10px 0;
}
.upload span {
  margin-left: 15px;
  color: #a6abc7;
}
.upload .ChooseFile {
  width: 370px;
  height: 40px;
  background: #edeef4;
  border: none;
  color: #909399;
}
div/deep/.el-upload--picture-card {
  width: 100%;
  height: 234px;
  font-size: 16px;
  font-family: Microsoft YaHei, Microsoft YaHei-Bold;
  font-weight: 700;
  text-align: center;
  line-height: 234px;
  position: relative;
}
.id_img {
  width: 80%;
  position: absolute;
  top: 10%;
  left: 10%;
}
.id_text {
  z-index: 9;
  position: absolute;
  transform: translateX(-48px);
}
.id_box {
  width: 100%;
  display: flex;
}
.id_flex {
  width: 370px !important;
  margin-right: 24px;
  position: relative;
}
div/deep/.el-upload-list--picture-card .el-upload-list__item {
  position: absolute;
  width: 80%;
  height: 82%;
  left: 50%;
  top: 24px;
  transform: translateX(-50%);
  z-index: 199;
}
</style>
